<template>
	<div id="stationDetails">
		<index>
			<div class="u-f-item u-f-jsb head">
				<div>{{title}}岗位表</div>
				<el-dropdown @command="handleCommand">
					<el-button type="primary" size="small">
						新增学科
						<i class="el-icon-arrow-down el-icon--right"></i>
					</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="1">标准学科</el-dropdown-item>
						<!-- <el-dropdown-item>单双周</el-dropdown-item>
						<el-dropdown-item>合班</el-dropdown-item>
						<el-dropdown-item>自定义</el-dropdown-item> -->
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<el-row :gutter="20">
				<el-col :span="18">
					<div class="content-left">
						<div class="head u-f-item u-f-jsb">
							<div class="u-f-item">
								<!-- <div>
									<span style="color: #A4A4A4;">下级行政：</span>
									村老师
								</div>
								<div style="margin-left: 20px;">
									<span style="color: #A4A4A4;">级长：</span>
									白老师
								</div> -->
							</div>
							<div class="u-f-item">
								<div class="lable u-f-item">
									<div class="u-f-item">
										<i class="iconfont icon-yuandian1"></i>
										标准学科
									</div>
									<span>|</span>
									<div class="u-f-item">
										<i class="iconfont icon-yuandian1"></i>
										单双周
									</div>
									<span>|</span>
									<div class="u-f-item">
										<i class="iconfont icon-yuandian1"></i>
										合班
									</div>
									<span>|</span>
									<div class="u-f-item">
										<i class="iconfont icon-yuandian1"></i>
										自定义
									</div>
								</div>
								<el-button type="primary" size="mini" @click="save">保存</el-button>
							</div>
						</div>
						<div class="content">
							<div class="content_master_table">
								<div class="master_class_list">
									<span class="master_class_title">班级</span>
									<div class="class_item" v-for="(item,index) in gradeClass" :key="index">
										<span class="class_item_name">{{item.name}}</span>
										<span class="class_item_type one">{{item.character_type_text}}</span>
									</div>
								</div>
								
								<div class="master_content_cube">
									<div class="cube_header">
										<div class="cube_header_title">
											<div class="cube_header_item" style="border-top: none;">
												<span>科目1</span>
											</div>
											<div class="cube_header_item">
												<span>周课时</span>
											</div>
											<div class="cube_header_line">
												<span class="prepares">备长</span>
												<span class="l_teacher">班主任</span>
												<span class="linex"></span>
											</div>
										</div>
									</div>
									<div class="cube_header clone" style="width:92.4%">
										<div class="cube_header_title">
											<div class="cube_header_item" style="border-top: none;">
												<span>科目</span>
											</div>
											<div class="cube_header_item">
												<span>周课时</span>
											</div>
											<div class="cube_header_line">
												<span class="prepares">备长</span>
												<span class="l_teacher">班主任</span>
												<span class="linex"></span>
											</div>
										</div>
										<div class="cube_header_list">
											<div class="cube_course_list">
												<div class="cube_course_item one " v-if="item.discipline_type==1" :class="index==0?'course_active':''" v-for="(item,index) in disciplineData" :key="index">
													<!-- <el-tooltip class="item" effect="dark" content="点击可编辑" placement="top">
													    <span class="el-tooltip routine item">{{item.name}}</span>
													</el-tooltip> -->
													<span class="el-tooltip routine item">{{item.name}}</span>
												</div>
												<div class="cube_course_item two " v-if="item.discipline_type==2" :class="index==0?'course_active':''" v-for="(item,index) in disciplineData" :key="index">
													<span class="el-tooltip routine item">{{item.name}}</span>
												</div>
												<div class="cube_course_item thr " v-if="item.discipline_type==3" :class="index==0?'course_active':''" v-for="(item,index) in disciplineData" :key="index">
													<span class="el-tooltip routine item">{{item.name}}</span>
												</div>
												<div class="cube_course_item for " v-if="item.discipline_type==4" :class="index==0?'course_active':''" v-for="(item,index) in disciplineData" :key="index">
													<span class="el-tooltip item">
														<span class="odd_name">语文(单)</span>
														<span class="even_name">英语(双)</span>
													</span>
												</div>
											</div>
											<div class="input_numbers">
												<div class="input_number_box" :class="index==0?'course_active':''" v-for="(item,index) in disciplineData" :key="index">
													<div class="input_number_item">
														<el-input-number v-model="item.week_class" @change="handleChange" :min="0"></el-input-number>
													</div>
												</div>
											</div>
											<div class="cube_teachers">
												<div class="cube_teacher_item" :class="index==0?'course_active':''" v-for="(item,index) in disciplineData" :key="index">
													<span class="cube_teacher_item-name" v-if="item.prepare">
														{{item.prepare.teacher_name}}
														<span class="teacher_blank_circle" @click="delTeacher(index)">
															<i class="el-icon-close"></i>
														</span>
													</span>
													<i class="el-icon-plus" @click="addTeacher(index)" v-else></i>
												</div>
											</div>
										</div>
									</div>
									<div class="cube_content">
										<div class="charges">
											<div class="charge_item box" v-for="(item,index) in gradeClass" :key="index">
												<div class="charge_item mini" v-if="item.teacher_one_name">
													<span class="cube_teacher_item-name">{{item.teacher_one_name}}
														<span class="teacher_blank_circle" @click="delTeacherOne(index)">
															<i class="el-icon-close"></i>
														</span>
													</span>
												</div>
												<div class="charge_item item" v-else @click="addTeacherOne(index)">
													<i class="el-icon-plus"></i>
												</div>
												<div class="charge_item mini" v-if="item.teacher_two_name">
													<span class="cube_teacher_item-name">{{item.teacher_two_name}}
														<span class="teacher_blank_circle" @click="delTeacherTwo(index)">
															<i class="el-icon-close"></i>
														</span>
													</span>
												</div>
												<div class="charge_item item" v-else @click="addTeacherTwo(index)">
													<i class="el-icon-plus"></i>
												</div>
											</div>
										</div>
										<div class="class_column_row">
											<div class="class_column">
												<div class="course_row"  v-for="(items,index) in gradeClass" :key="index">
													<div class="teacher_blank" :class="i==0?'course_active':''" v-for="(item,i) in items.list" :key="i">
														<div class="teacher_week_time">
															<i class="el-icon-caret-left"></i>
															<span class="week_time">{{item.week_class}}</span>
															<i class="el-icon-caret-right"></i>
														</div>
														<template v-if="item.discipline_type!=4">
															<div class="teacher_blank_item" v-if="item.teacher">
																<div class="teacher_blank_one">
																	<span class="teacher_blank_name">{{item.teacher.teacher_name}}</span>
																	<span class="teacher_blank_circle" @click="delKemu1(index,i)"><i class="el-icon-close"></i></span>
																</div>
															</div>
															<div class="teacher_blank_item active" @click="addKemu1(index,i)" v-else></div>
														</template>
														<template  v-if="item.discipline_type==4">
															<div class="teacher_blank_item" v-if="item.teacher.length">
																<div class="teacher_blank_one">
																	<span class="teacher_blank_name" v-for="(techer,t) in item.teacher" :key="t">{{techer.teacher_name}}</span>
																	<span class="teacher_blank_circle" @click="delKemu(index,i)"><i class="el-icon-close"></i></span>
																</div>
															</div>
															<div class="teacher_blank_item active" @click="addKemu(index,i)" v-else></div>
														</template>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								
								<div class="count_course_time">
									<span class="master_class_title">总课时</span>
									<div class="class_item" v-for="(item,index) in gradeClass" :key="index">
										<span class="class_item_name">0</span>
									</div>
								</div>
								<div class="f">班级</div>
								<div class="fr">总课时</div>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="content-right">
						<div class="head">选择老师</div>
						<div class="subject-box">
							<el-input size="small" placeholder="请输入名称搜索" v-model="key"><i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
							<div class="subject-box-list">
								<div class="subject-list">
									<div class="subject-item" @click="xuekeTap(index)" :class="xuekeIndex==index?'active':''" v-for="(item,index) in xuekeList" :key="index">{{item.name}}</div>
								</div>
							</div>
						</div>
						<div class="teacher-box">
							<div class="u-f-item teacher-item" @click="laoshiTap(index)" :class="index==laoshiIndex?'active':''" v-for="(item,index) in laoshiList" :key="index">
								{{item.teacher_name}}
								<span>{{item.week_class}}</span>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
			
			<!-- 添加标准学科 -->
			<el-dialog title="新建标准学科" :visible.sync="standardShow" width="35%" :before-close="handleClose">
				<div class="editorBox">
					<div class="u-f-item div">
						<div class="input-lebal u-f1" style="text-align: right;">
							选择标准学科
							<span>*</span>
						</div>
						<div class="u-f4">
							<div class="weeks">
								<div :class="index==gradeIndex?'active':''" v-for="(item, index) in GradeDisciplineList" :key="index" @click="weeksChange(index)">{{ item.name }}</div>
							</div>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" plain @click="close">取消</el-button>
					<el-button type="primary" @click="submmitadd">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			id: '',
			key: '',
			num:0,
			info:'',
			xuekeList:[],
			xuekeIndex:0,
			laoshiList:[],
			disciplineData:[],
			gradeClass:[],
			standardShow:false,
			GradeDisciplineList:[],
			gradeIndex:0,
			gradeId:'',
			radio:'',
			laoshiIndex:-1,
			title:''
		};
	},
	mounted() {
		this.id = this.$route.query.id;
		this.gradeId = this.$route.query.gradeId;
		this.title = this.$route.query.title;
		this.getJobConfig()
		this.getXuekeList()
		this.getGradeDisciplineList()
	},
	methods: {
		laoshiTap(index){
			this.laoshiIndex = index;
		},
		handleCommand(type){
			this.standardShow = true;
		},
		handleClose(done){
			done()
		},
		close(){
			this.standardShow = false;
		},
		handleChange(){
			
		},
		weeksChange(index){
			this.gradeIndex = index;
		},
		// 删除备长
		delTeacher(index){
			this.disciplineData[index].prepare = '';
		},
		// 添加备长
		addTeacher(index){
			if(this.laoshiIndex!=-1&&this.laoshiList[this.laoshiIndex].teacher_name){
				let arr = {
					teacher_id:this.laoshiList[this.laoshiIndex].teacher_id,
					teacher_name:this.laoshiList[this.laoshiIndex].teacher_name
				};
				this.disciplineData[index].prepare = arr;
			}else{
				this.$message.error('请选择教师')
			}
			
		},
		// 删除班主任1
		delTeacherOne(index){
			this.gradeClass[index].teacher_one_name = '';
			this.gradeClass[index].teacher_one = 0;
		},
		// 添加班主任1
		addTeacherOne(index){
			if(this.laoshiIndex!=-1&&this.laoshiList[this.laoshiIndex].teacher_name){
				this.gradeClass[index].teacher_one_name = this.laoshiList[this.laoshiIndex].teacher_name;
				this.gradeClass[index].teacher_one = this.laoshiList[this.laoshiIndex].teacher_id;
			}else{
				this.$message.error('请选择教师')
			}
		},
		// 删除班主任2
		delTeacherTwo(index){
			this.gradeClass[index].teacher_two_name = '';
			this.gradeClass[index].teacher_two = 0;
		},
		// 添加班主任2
		addTeacherTwo(index){
			if(this.laoshiIndex!=-1&&this.laoshiList[this.laoshiIndex].teacher_name){
				this.gradeClass[index].teacher_two_name = this.laoshiList[this.laoshiIndex].teacher_name;
				this.gradeClass[index].teacher_two = this.laoshiList[this.laoshiIndex].teacher_id;
			}else{
				this.$message.error('请选择教师')
			}
		},
		// 添加科目老师
		addKemu(index,i){
			if(this.laoshiIndex!=-1&&this.laoshiList[this.laoshiIndex].teacher_name){
				let arr = [];
				arr.push({
					teacher_id:this.laoshiList[this.laoshiIndex].teacher_id,
					teacher_name:this.laoshiList[this.laoshiIndex].teacher_name
				});
				this.gradeClass[index].list[i].teacher = arr;
				console.log(this.gradeClass[index].list[i].teacher)
			}else{
				this.$message.error('请选择教师')
			}
		},
		// 删除科目老师
		delKemu(index,i){
			this.gradeClass[index].list[i].teacher = [];
		},
		// 添加科目老师
		addKemu1(index,i){
			if(this.laoshiIndex!=-1&&this.laoshiList[this.laoshiIndex].teacher_name){
				let arr = {
					teacher_id:this.laoshiList[this.laoshiIndex].teacher_id,
					teacher_name:this.laoshiList[this.laoshiIndex].teacher_name
				};
				this.gradeClass[index].list[i].teacher = arr;
				console.log(this.gradeClass[index].list[i])
			}else{
				this.$message.error('请选择教师')
			}
		},
		// 删除科目老师
		delKemu1(index,i){
			this.gradeClass[index].list[i].teacher = '';
		},
		// 获取年级标准学科列表
		getGradeDisciplineList(){
			this.$api.station.getGradeDiscipline({id:this.gradeId}).then(res=>{
				if(res.data.code==1){
					let data = res.data.data;
					this.GradeDisciplineList = data;
				}
			})
		},
		// 保存数据
		save(){
			this.$api.station.saveDiscipline(this.id,{
				grade_class:this.gradeClass,
				disciplineData:this.disciplineData
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('保存成功')
					this.getJobConfig()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		submmitadd(){
			let data = {
				id: this.GradeDisciplineList[this.gradeIndex].id,
				name:this.GradeDisciplineList[this.gradeIndex].name,
				discipline_type: 1
			}
			this.$api.station.addDiscipline(this.id,data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功')
					this.getJobConfig()
					this.standardShow = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 获取配置信息
		getJobConfig(){
			this.$api.station.getJobConfig({id:this.id}).then(res=>{
				if(res.data.code==1){
					this.disciplineData = res.data.data.disciplineData;
					let data = res.data.data.grade_class;
					this.gradeClass = data;
				}
			})
		},
		getXuekeList(){
			this.$api.station.getXuekeList({job_id:this.id}).then(res=>{
				if(res.data.code==1){
					this.xuekeList = res.data.data;
					this.getLaoshiList()
				}
			})
		},
		getLaoshiList(){
			this.$api.station.getLaoshiList({
					job_discipline_id:this.xuekeList[this.xuekeIndex].job_discipline_id,
				}).then(res=>{
				if(res.data.code==1){
					this.laoshiList = res.data.data;
				}
			})
		},
		// 切换学科
		xuekeTap(index){
			this.xuekeIndex = index;
			this.getLaoshiList()
		}
	}
};
</script>
<style lang="scss">
#stationDetails {
	.head {
		height: 6vh;
		background-color: #ffffff;
		margin-bottom: 1vh;
		padding: 0 20px;
	}
	.content-left {
		.head {
			background-color: #ffffff;
			padding: 0 20px;
			height: 60px;
			margin-bottom: 5px;
			.lable {
				background-color: #f6f8fb;
				height: 30px;
				font-size: 12px;
				padding: 0 15px;
				line-height: 30px;
				margin-right: 20px;
				div:nth-of-type(1) {
					i {
						color: #38c3a1;
					}
				}
				div:nth-of-type(2) {
					i {
						color: #ff7a65;
					}
				}
				div:nth-of-type(3) {
					i {
						color: #ffc904;
					}
				}
				div:nth-of-type(4) {
					i {
						color: #a190f7;
					}
				}
				span {
					color: #eeeeee;
					padding: 0 5px;
				}
			}
		}
		.content {
			background-color: #ffffff;
			height: 72vh;
			padding: 15px 10px 15px 20px;
			.content_master_table{
				position: relative;
				width: 100%;
				height: 100%;
				// min-width: 68vw;
				border: 1px solid #E8EAEC;
				padding-left: 5.20833333vw;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				.master_class_list{
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 5.20833333vw;
					min-width: 53.33333333px;
					background-color: #F6F8FB;
					box-shadow: -2px 0 4px #F7F7F7;
					border-right: 1px solid #E8EAEC;
					max-height: 39.16666667vw;
					overflow-y: auto;
					overflow-x: auto;
					pointer-events: none;
					padding-bottom: 50px;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					.master_class_title{
						height: 8.85416667vw;
						min-height: 90.66666667px;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						-webkit-box-orient: vertical;
						-webkit-box-direction: normal;
						-ms-flex-direction: column;
						flex-direction: column;
						font-size: 0.625vw;
						color: #4C5559;
					}
					.class_item{
						width: 100%;
						border-top: 1px solid #E8E9EA;
						height: 4.6875vw;
						min-height: 48px;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						-webkit-box-orient: vertical;
						-webkit-box-direction: normal;
						-ms-flex-direction: column;
						flex-direction: column;
						.class_item_name{
							font-size: 0.625vw;
							color: #4C5559;
							margin-bottom: .15625vw;
						}
						.class_item_type{
							min-width: 26.66666667px;
							min-height: 10.66666667px;
							font-size: 0.625vw;
						}
						.one{
							color: #1EC5E2;
						}
						.two{
							color: #FFC904;
						}
						.thr{
							color: #FF7A65;
						}
						.for{
							color: #A190F7;
						}
						.fir{
							color: #BCB2F1;
						}
					}
				}
				.master_content_cube{
					display: block;
					width: 100%;
					height: 100%;
					max-height: 39.16666667vw;
					overflow-x: auto;
					overflow-y: auto;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					.cube_header{
						box-sizing: border-box;
						height: 8.85416667vw;
						min-height: 90.66666667px;
						position: relative;
						display: flex;
						.cube_header_title{
							min-width: 66px;
							background-color: #F6F8FB;
							border-right: 1px solid #E8EAEC;
							box-sizing: border-box;
							height: 8.85416667vw;
							min-height: 90.66666667px;
							.cube_header_item{
								width: 100%;
								font-size: 0.625vw;
								color: #4C5559;
								display: flex;
								justify-content: center;
								align-items: center;
								-webkit-box-orient: vertical;
								-webkit-box-direction: normal;
								-ms-flex-direction: column;
								flex-direction: column;
								height: 2.60416667vw;
								min-height: 26.66666667px;
								box-sizing: border-box;
								border-top: 1px solid #E8EAEC;
							}
							.cube_header_line{
								position: relative;
								width: 100%;
								font-size: 0.625vw;
								color: #4C5559;
								height: 3.64583333vw;
								min-height: 37.33333333px;
								-webkit-box-sizing: border-box;
								box-sizing: border-box;
								border-top: 1px solid #E8EAEC;
								overflow: hidden;
								.prepares{
									position: absolute;
									right: .52083333vw;
									top: .52083333vw;
									transform: scale(.9);
								}
								.l_teacher{
									position: absolute;
									left: .52083333vw;
									bottom: .52083333vw;
									transform: scale(.9);
								}
								.linex{
									content: "";
									position: absolute;
									top: 45%;
									left: -.5vw;
									width: 5.6416667vw;
									min-width: 65.06666667px;
									height: 1px;
									background: #E8EAEC;
									transform: rotate(36deg);
								}
							}
						}
						.cube_header_list{
							padding-right: 3.64583333vw;
							flex: 1;
							box-sizing: border-box;
							.cube_course_list{
								width: 100%;
								display: flex;
								justify-content: flex-start;
								align-items: center;
								-webkit-box-orient: horizontal;
								-webkit-box-direction: normal;
								-ms-flex-direction: row;
								flex-direction: row;
								background-color: #F6F8FB;
								box-sizing: border-box;
								border-bottom: 1px solid #E8EAEC;
								.cube_course_item{
									flex: 1;
									min-width: 100px;
									height: 2.60416667vw;
									min-height: 26.66666667px;
									font-size: 0.625vw;
									cursor: pointer;
									border-right: 1px solid #E8EAEC;
									.item{
									    display: flex;
									    -webkit-box-pack: center;
									    justify-content: center;
									    -webkit-box-align: center;
									    align-items: center;
									    -webkit-box-orient: vertical;
									    -webkit-box-direction: normal;
										flex-direction: column;
										width: 100%;
										height: 100%;
										.odd_name{
											margin-bottom: .10416667vw;
										}
									}
								}
								.cube_course_item:hover{
									background-color: #EDEAFA;
								}
								.course_active{
									background-color: #E7F9F1;
								}
								.one{
									color: #38C3A1;
								}
								.two{
									color: #FF7A65;
								}
								.thr{
									color: #FFC904;
								}
								.for{
									color: #A190F7;
								}
							}
							.input_numbers{
								width: 100%;
								display: flex;
								justify-content: flex-start;
								align-items: center;
								-webkit-box-orient: horizontal;
								-webkit-box-direction: normal;
								-ms-flex-direction: row;
								flex-direction: row;
								background-color: #F6F8FB;
								box-sizing: border-box;
								.input_number_box{
									flex: 1;
									min-width: 100px;
									height: 2.60416667vw;
									min-height: 26.66666667px;
									display: flex;
									justify-content: center;
									align-items: center;
									flex-direction: column;
									border-right: 1px solid #E8EAEC;
									padding-right: .52083333vw;
									padding-left: .52083333vw;
									box-sizing: border-box;
									border-bottom: 1px solid #E8EAEC;
									.input_number_item{
										width: 4.16666667vw;
										min-width: 42.66666667px;
										height: 1.875vw;
										min-height: 19.2px;
										position: relative;
										display: -webkit-box;
										display: -ms-flexbox;
										display: flex;
										-webkit-box-pack: center;
										-ms-flex-pack: center;
										justify-content: center;
										-webkit-box-align: center;
										-ms-flex-align: center;
										align-items: center;
										-webkit-box-orient: vertical;
										-webkit-box-direction: normal;
										-ms-flex-direction: column;
										flex-direction: column;
										.el-input-number{
										    width: 4.16666667vw;
										    min-width: 42.66666667px;
										    height: 1.875vw;
										    min-height: 19.2px;
											.el-input-number__decrease,
											.el-input-number__increase{
												width: 1.04166667vw;
												min-width: 10.66666667px;
												height: 90%;
												top: 1px;
												-webkit-transition: opacity .3s;
												transition: opacity .3s;
												display: -webkit-box;
												display: -ms-flexbox;
												display: flex;
												-webkit-box-pack: center;
												-ms-flex-pack: center;
												justify-content: center;
												-webkit-box-align: center;
												-ms-flex-align: center;
												align-items: center;
												-webkit-box-orient: vertical;
												-webkit-box-direction: normal;
												-ms-flex-direction: column;
												flex-direction: column;
												font-size: 0.625;
											}
											.el-input{
												height: 100%;
												display: -webkit-box;
												display: -ms-flexbox;
												display: flex;
												-webkit-box-pack: center;
												-ms-flex-pack: center;
												justify-content: center;
												-webkit-box-align: center;
												-ms-flex-align: center;
												align-items: center;
												-webkit-box-orient: vertical;
												-webkit-box-direction: normal;
												-ms-flex-direction: column;
												flex-direction: column;
												.el-input__inner {
												    text-align: center;
												    height: 1.875vw;
												    min-height: 19.2px;
												    padding-left: 0;
												    padding-right: 0;
												}
											}
										}
									}
								}
								.course_active{
									background-color: #E7F9F1;
								}
							}
							.cube_teachers{
								width: 100%;
								display: flex;
								justify-content: flex-start;
								align-items: center;
								-webkit-box-orient: horizontal;
								-webkit-box-direction: normal;
								-ms-flex-direction:row;
								box-sizing: border-box;
								border-bottom: none;
								height: 3.64583333vw;
								min-height: 37.33333333px;
								background-color: #fff;
								.cube_teacher_item {
									position: relative;
									-webkit-box-flex: 1;
									-ms-flex: 1;
									flex: 1;
									min-width: 100px;
									border-right: 1px solid #E8EAEC;
									overflow: hidden;
									height: 3.64583333vw;
									min-height: 37.33333333px;
									cursor: pointer;
									-ms-flex-pack: center;
									-ms-flex-align: center;
									-ms-flex-direction: column;
									flex-direction: column;
									display: flex;
									-webkit-box-pack: center;
									justify-content: center;
									-webkit-box-align: center;
									align-items: center;
									-webkit-box-orient: vertical;
									-webkit-box-direction: normal;
									.cube_teacher_item-name{
										font-size: 0.625vw;
										color: #4C5559;
										display: inline-block;
										width: 2.60416667vw;
										white-space: nowrap;
										overflow: hidden;
										text-overflow: ellipsis;
										.teacher_blank_circle{
											position: absolute;
											background-color: #FF7A65;
											cursor: pointer;
											width: 1.04166667vw;
											min-width: 10.66666667px;
											display: -webkit-box;
											display: -ms-flexbox;
											display: flex;
											-webkit-box-pack: center;
											-ms-flex-pack: center;
											justify-content: center;
											-webkit-box-align: center;
											-ms-flex-align: center;
											align-items: center;
											-webkit-box-orient: vertical;
											-webkit-box-direction: normal;
											-ms-flex-direction: column;
											flex-direction: column;
											right: -1.04166667vw;
											top: 0;
											height: 100%;
											-webkit-transition: opacity .3s;
											transition: opacity .3s;
											-webkit-transition: right .3s;
											transition: right .3s;
											opacity: 0;
											i{
												display: inline-block;
												font-size: 8px;
												color: #fff;
												-webkit-transform: scale(.5);
												transform: scale(.5);
											}
										}
										.el-icon-plus{
											color: #38C3A1;
											font-size: 0.625vw;
										}
									}
									.el-icon-plus{
										width: 100%;
										height: 100%;
										text-align: center;
										line-height: 3.64583333vw;;
									}
								}
								.cube_teacher_item:hover{
									background-color: #EDEAFA;
									.teacher_blank_circle{
										right: 0;
										opacity:1;
									}
								}
								.course_active{
									background-color: #E7F9F1;
								}
							}
						}
					}
					.clone{
						position: absolute;
						z-index: 1;
						left: 5.20833333vw;
						top: 0;
						// overflow-x: scroll;
						box-sizing: border-box;
						border-bottom: 1px solid #E8EAEC;
					}
					.cube_content{
						display: flex;
						box-sizing: border-box;
						width: 100%;
						height: calc(100% - 8.85416667vw);
						.charges{
							min-width: 66px;
							box-sizing: border-box;
							border-right: 1px solid #E8EAEC;
							.charge_item{
								position: relative;
								width: 100%;
								border-top: 1px solid #E8EAEC;
								overflow: hidden;
								height: 4.6875vw;
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								-webkit-box-pack: center;
								-ms-flex-pack: center;
								justify-content: center;
								-webkit-box-align: center;
								-ms-flex-align: center;
								align-items: center;
								-webkit-box-orient: vertical;
								-webkit-box-direction: normal;
								-ms-flex-direction: column;
								flex-direction: column;
								cursor: pointer;
								.cube_teacher_item-name{
								    font-size: 0.625vw;
								    color: #4C5559;
								    display: inline-block;
								    width: 2.60416667vw;
								    white-space: nowrap;
								    overflow: hidden;
								    text-overflow: ellipsis;
									.teacher_blank_circle{
										position: absolute;
										background-color: #FF7A65;
										cursor: pointer;
										width: 1.04166667vw;
										min-width: 10.66666667px;
										display: -webkit-box;
										display: -ms-flexbox;
										display: flex;
										-webkit-box-pack: center;
										-ms-flex-pack: center;
										justify-content: center;
										-webkit-box-align: center;
										-ms-flex-align: center;
										align-items: center;
										-webkit-box-orient: vertical;
										-webkit-box-direction: normal;
										-ms-flex-direction: column;
										flex-direction: column;
										right: -1.04166667vw;
										top: 0;
										height: 100%;
										-webkit-transition: opacity .3s;
										transition: opacity .3s;
										-webkit-transition: right .3s;
										transition: right .3s;
										opacity: 0;
										.el-icon-close{
											display: inline-block;
											font-size: 8px;
											color: #fff;
											-webkit-transform: scale(.5);
											transform: scale(.5);
										}
									}
								}
								.el-icon-plus{
									color: #38C3A1;
									font-size: 0.625vw;
								}
							}
							.mini:hover,.item:hover{
								background-color: #EDEAFA;
							}
							.charge_item :hover{
								.teacher_blank_circle{
									opacity: 1;
									right: 0;
								}
							}
							.box{
								display: flex;
								-webkit-box-orient: vertical;
								-webkit-box-direction: normal;
								-ms-flex-direction: column;
								flex-direction: column;
							}
						}
						.class_column_row{
							-webkit-box-flex: 1;
							-ms-flex: 1;
							flex: 1;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							display: flex;
							-webkit-box-sizing: border-box;
							box-sizing: border-box;
							padding-right: 3.6vw;
							.class_column{
								-webkit-box-flex: 1;
								-ms-flex: 1;
								flex: 1;
								min-width: 100px;
								height: 100%;
								border-right: 1px solid #E8EAEC;
								.course_row{
									width: 100%;
									display: flex;
									-webkit-box-direction: normal;
									height: 4.6875vw;
									min-height: 48px;
									border-top: 1px solid #E8EAEC;
									-webkit-box-pack: start;
									-ms-flex-pack: start;
									justify-content: flex-start;
									-webkit-box-align: start;
									-ms-flex-align: start;
									align-items: flex-start;
									.teacher_blank{
										-webkit-box-flex: 1;
										-ms-flex: 1;
										flex: 1;
										min-width: 100px;
										height: 100%;
										border-right: 1px solid #E8EAEC;
										.teacher_week_time{
											height: 1.04166667vw;
											min-height: 10.66666667px;
											padding-right: .52083333vw;
											padding-left: .52083333vw;
											-webkit-box-sizing: border-box;
											box-sizing: border-box;
											background-color: #F6F6F6;
											-webkit-box-pack: center;
											-ms-flex-pack: center;
											justify-content: center;
											-webkit-box-align: center;
											-ms-flex-align: center;
											align-items: center;
											-webkit-box-orient: horizontal;
											-ms-flex-direction: row;
											flex-direction: row;
											width: 100%;
											display: -webkit-box;
											display: -ms-flexbox;
											display: flex;
											-webkit-box-direction: normal;
											.week_time{
												font-size: 0.625vw;
												color: #8D9DA6;
											}
											i{
												display: none;
												cursor: pointer;
												color: #C0C4CC;
												-webkit-box-flex: 1;
												-ms-flex: 1;
												flex: 1;
												-webkit-transform: scale(.7);
												transform: scale(.7);
												text-align: right;
											}
											.el-icon-caret-left{
												text-align: left;
											}
										}
										.teacher_week_time:hover{
											i{
												display: block;
											}
										}
										.teacher_blank_item{
											width: 100%;
											height: 3.64583333vw;
											min-height: 37.33333333px;
											display: -webkit-box;
											display: -ms-flexbox;
											display: flex;
											-webkit-box-pack: center;
											-ms-flex-pack: center;
											justify-content: center;
											-webkit-box-align: center;
											-ms-flex-align: center;
											align-items: center;
											-webkit-box-orient: vertical;
											-webkit-box-direction: normal;
											-ms-flex-direction: column;
											flex-direction: column;
											cursor: pointer;
											.teacher_blank_one{
												position: relative;
												width: 100%;
												height: 100%;
												overflow: hidden;
												display: -webkit-box;
												display: -ms-flexbox;
												display: flex;
												-webkit-box-pack: center;
												-ms-flex-pack: center;
												justify-content: center;
												-webkit-box-align: center;
												-ms-flex-align: center;
												align-items: center;
												-webkit-box-orient: horizontal;
												-webkit-box-direction: normal;
												-ms-flex-direction: row;
												flex-direction: row;
												font-size: 0.625vw;
												color: #4C5559;
												.teacher_blank_name{
													margin-right: .52083333vw;
												}
												.teacher_blank_circle{
													position: absolute;
													background-color: #FF7A65;
													cursor: pointer;
													width: 1.04166667vw;
													min-width: 10.66666667px;
													display: -webkit-box;
													display: -ms-flexbox;
													display: flex;
													-webkit-box-pack: center;
													-ms-flex-pack: center;
													justify-content: center;
													-webkit-box-align: center;
													-ms-flex-align: center;
													align-items: center;
													-webkit-box-orient: vertical;
													-webkit-box-direction: normal;
													-ms-flex-direction: column;
													flex-direction: column;
													right: -1.04166667vw;
													top: 0;
													height: 100%;
													-webkit-transition: opacity .3s;
													transition: opacity .3s;
													-webkit-transition: right .3s;
													transition: right .3s;
													opacity: 0;
													i{
														display: inline-block;
														font-size: 8px;
														color: #fff;
														-webkit-transform: scale(.5);
														transform: scale(.5);
													}
												}
											}
											.teacher_blank_one:hover{
												.teacher_blank_circle{
													opacity: 1;
													right:0;
												}
											}
										}
										.teacher_blank_item:hover{
											background-color: #EDEAFA;
										}
									}
									.course_active{
									    background-color: #E7F9F1;
										.teacher_week_time{
											background-color: #B8E9E2;
											.week_time{
												color: #fff;
											}
										}
									}
								}
							}
						}
					}
				}
				.count_course_time{
					position: absolute;
					z-index: 2;
					border-left: 1px solid #E8EAEC;
					height: 100%;
					width: 3.64583333vw;
					min-width: 37.33333333px;
					background-color: #F6F8FB;
					max-height: 39.16666667vw;
					right: 0;
					top: 0;
					-webkit-box-shadow: -2px 0 4px #F7F7F7;
					box-shadow: -2px 0 4px #F7F7F7;
					overflow: auto;
					pointer-events: none;
					padding-bottom: 50px;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					.master_class_title{
						height: 8.85416667vw;
						min-height: 90.66666667px;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						-webkit-box-orient: vertical;
						-webkit-box-direction: normal;
						-ms-flex-direction: column;
						flex-direction: column;
						font-size: 0.625vw;
						color: #4C5559;
					}
					.class_item{
						width: 100%;
						border-top: 1px solid #E8EAEC;
						height: 4.6875vw;
						min-height: 48px;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						-webkit-box-orient: vertical;
						-webkit-box-direction: normal;
						-ms-flex-direction: column;
						flex-direction: column;
						.class_item_name{
							font-size: 0.625vw;
							color: #4C5559;
							margin-bottom: .15625vw;
						}
					}
				}
				.f{
					left: 0;
					top: 0;
					border-right: 1px solid #E8EAEC;
				}
				.f, .fr{
				    position: absolute;
				    width: 5.20833333vw;
				    min-width: 53.33333333px;
				    background-color: #F6F8FB;
				    z-index: 99;
				    -webkit-box-sizing: border-box;
				    box-sizing: border-box;
				    border-bottom: 1px solid #E8EAEC;
					height: 8.85416667vw;
					min-height: 90.66666667px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-ms-flex-direction: column;
					flex-direction: column;
					font-size: 0.625vw;
					color: #4C5559;
				}
				.fr{
				
				    width: 3.64583333vw;
				    min-width: 37.33333333px;
				    right: 0;
				    top: 0;
				    border-left: 1px solid #E8EAEC;
				
				}
			}
		}
	}
	.content-right {
		.head {
			background-color: #e4e9f5;
			height: 65px;
			line-height: 65px;
			padding: 0 20px;
		}
		.subject-box {
			height: 20vh;
			background-color: #ffffff;
			padding: 15px;
			margin-bottom: 5px;
			.el-input__inner {
				border-radius: 25px;
			}
			.subject-box-list{
				margin-top: 15px;
				height: 15vh;
				overflow-y: auto;
			}
			.subject-list {
				display: flex;
				flex-wrap: wrap;
				.subject-item {
					background-color: #eeeeee;
					display: inherit;
					padding: 0 15px;
					margin-right: 15px;
					height: 30px;
					line-height: 30px;
					margin-bottom: 15px;
					border-radius: 5px;
					cursor: pointer;
				}
				.active {
					color: #407bf0;
				}
			}
		}
		.teacher-box {
			height: 47.4vh;
			background-color: #ffffff;
			padding: 15px;
			overflow-y: auto;
			display: flex;
			flex-wrap: wrap;
			.teacher-item {
				height: 30px;
				line-height: 30px;
				padding: 0 15px;
				margin-right: 15px;
				margin-bottom: 15px;
				background-color: #eeeeee;
				border-radius: 5px;
				cursor: pointer;
				span {
					color: #ccc;
					margin-left: 10px;
				}
			}
			.active {
				color: #407bf0;
				span {
					color: #81a7f3;
				}
			}
		}
	}
	
	.el-dialog__header {
		padding: 15px;
		font-size: 14px;
		border-bottom: 1px solid #eeeeee;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.editorBox {
		padding: 0 20px;
		.div {
			padding: 10px 0 20px 0;
			.input-lebal {
				font-weight: bold;
				padding-right: 10px;
				span {
					color: red;
					font-weight: 100;
					margin-left: 3px;
				}
			}
		}
		.weeks {
			display: flex;
			flex-wrap: wrap;
			div {
				background-color: #f3f3f3;
				padding: 6px 13px;
				margin-right: 10px;
				border-radius: 5px;
				margin-bottom: 10px;
				cursor: pointer;
			}
			div:nth-child(5n) {
				margin-right: 0;
			}
			.active{
				color: #409EFF;
			}
		}
	}
	.mergeBox {
		.table_box {
			margin-top: 15px;
			border-top: 1px solid #eeeeee;
			height: 50vh;
			overflow-y: scroll;
		}
	}
	.el-dialog__body {
		padding: 15px 20px;
	}
}
</style>
